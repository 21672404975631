import React, { useState, useContext } from 'react';
import * as S from './UserSubscriptions.styles';
import FunnelContext from '../../context/FunnelContext';

const UserSubscriptions = () => {
  const {
    extraObjects: { user },
    currency
  } = useContext(FunnelContext);

  const subscriptions = user?.subscriptions ?? [null];
  const currencySymbol = currency?.symbol || '$';

  const [currentIndex, setCurrentIndex] = useState(0);

  const currentSub = subscriptions[currentIndex];

  return (
    <S.Container>
      <S.OuterBorder>
        {currentSub && (
          <S.Row>
            <S.ColOne>
              <S.Img
                src={currentSub?.product_image}
                alt={currentSub?.product_name}
              />
            </S.ColOne>
            <S.ColTwo>
              <S.ProductName>{currentSub?.product_name}</S.ProductName>
              <S.Quantity>Quantity: {currentSub?.quantity}</S.Quantity>
            </S.ColTwo>
            <S.ColThree>
              <S.AmountDue>
                Due
                <br />
                {currencySymbol}
                {currentSub?.total_amount.toFixed(2)}
              </S.AmountDue>
            </S.ColThree>
          </S.Row>
        )}
        {!currentSub && <S.SubError>Error Loading Subscription!</S.SubError>}
      </S.OuterBorder>

      {subscriptions.length > 1 && (
        <S.DotContainer>
          {subscriptions.map((sub, index) => {
            return (
              <S.Dot
                key={index}
                onClick={() => setCurrentIndex(index)}
                className={currentIndex === index ? 'active' : ''}
              ></S.Dot>
            );
          })}
        </S.DotContainer>
      )}
    </S.Container>
  );
};

export default UserSubscriptions;
