import styled from 'styled-components';

export const Container = styled.div`
  background-color: #fff;
`;
export const OuterBorder = styled.div`
  border: 1px solid #f2d5d8;
  border-radius: 4px;
  box-shadow: 0px 4px 20px rgb(0 0 0 / 10%);
  padding: 15px;
`;

export const Row = styled.div`
  display: flex;
  flex-deriction: row;
  width: 100%;
  align-items: space-between;
`;

export const ColOne = styled.div`
  width: 60px;
  height: 60px;
  margin-right: 20px;
`;

export const ColTwo = styled.div`
  width: calc((100% / 4) * 2);
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  text-align: left;
  font-family: 'Neuzeit Grotesk', sans-serif;
  color: rgba(0, 28, 114, 1);
`;

export const ColThree = styled.div`
  margin-left: auto;
  margin-top: auto;
  margin-bottom: auto;
`;

export const Img = styled.img`
  height: 60px;
  width: 60px;
`;

export const ProductName = styled.div``;
export const Quantity = styled.div``;

export const AmountDue = styled.div`
  text-align: center;
  font-weight: 600;
  color: rgba(188, 46, 62, 1);
  font-family: 'Neuzeit Grotesk', sans-serif;
`;

export const DotContainer = styled.div`
  display: block;
  width: 100%;
  text-align: center;
`;

export const Dot = styled.span`
  width: 8px;
  height: 8px;
  background-color: #ccd2e3;
  border-radius: 100%;
  margin: 10px 5px;
  cursor: pointer;
  display: inline-block;
  &.active {
    background-color: #001c72;
  }
`;

export const SubError = styled.div`
  color: red;
  text-align: center;
  width: 100%;
`;
